import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { ButtonComponent } from '@design/buttons/button/button.component';

export enum ConfirmationDialogAppearance {
  Default = 'default',
  Compact = 'compact',
}

export interface ConfirmationDialogData {
  title?: string;
  message?: string;
  confirmText?: string;
  secondaryActionText?: string | null;
  cancelText?: string;
  destructive?: boolean;
  style: ConfirmationDialogAppearance;
  width?: string;
}

export type ConfirmationDialogResult = 'confirm' | 'secondaryAction' | 'cancel';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent],
})
export class ConfirmationDialogComponent {
  readonly dialogData: ConfirmationDialogData = inject(DIALOG_DATA);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  protected readonly dialogRef: DialogRef<ConfirmationDialogResult> = inject(DialogRef);
}
