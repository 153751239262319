<div
  (ccResize)="handleSelectResize($event)"
  class="cc-select"
>
  <div class="cc-select__header">
    <span class="cc-select__header-title">
      @if (required) {
        <span class="cc-select__header-title-required">*</span>
      }

      <span class="cc-select__header-title-label">
        <ng-content select="[ccTitle]"></ng-content>
      </span>

      @if (tooltip) {
        <i
          class="cc-select__header-title-tooltip icon-info-fill"
          [ccTooltip]="tooltip"
        ></i>
      }
    </span>

    <span class="cc-select__header-description">
      <ng-content select="[ccDescription]"></ng-content>
    </span>
  </div>

  <cc-select-preview
    #select
    (toggle)="toggleSelect()"
    [size]="size"
    [appearance]="appearance"
    [disabled]="disabled"
    [hasError]="!!errorMessage"
    [placeholder]="placeholder"
    [value]="defaultPreviewString"
    [dropdownOpen]="selectDropdownOpen"
  />

  @if (errorMessage) {
    <span class="cc-select__error">
      <i class="cc-select__error-icon icon-error-fill"></i>

      <span class="cc-select__error-message">
        {{ errorMessage }}
      </span>
    </span>
  }
</div>

<ng-template #dropdownItems>
  <ng-content select="[ccOptions]"></ng-content>
</ng-template>

<ng-template
  (detach)="selectDropdownOpen = false"
  (overlayOutsideClick)="
    selectDropdownOpen = getOverlayVisibilityAfterOutsideClick($event, select.trigger.nativeElement)
  "
  [cdkConnectedOverlayOpen]="selectDropdownOpen"
  [cdkConnectedOverlayOrigin]="select.trigger"
  [cdkConnectedOverlayPositions]="selectDropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown
    [maxHeight]="maxHeight"
    [shadow]="false"
    [width]="selectDropdownWidth"
  >
    <ng-container [ngTemplateOutlet]="dropdownItems"></ng-container>
  </cc-dropdown>
</ng-template>
