<div
  [class]="'confirmation-modal--' + dialogData.style"
  [style.width]="dialogData.width"
  class="confirmation-modal"
>
  <div class="confirmation-modal__header">
    <div class="confirmation-modal__header-title">{{ dialogData.title }}</div>

    <div class="confirmation-modal__header-description">
      {{ dialogData.message }}
    </div>
  </div>

  <div class="confirmation-modal__actions">
    @if (dialogData.confirmText) {
      <app-button
        (click)="dialogRef.close('confirm')"
        [size]="ButtonSize.Small"
        [type]="dialogData.destructive ? ButtonType.Danger : ButtonType.Primary"
      >
        {{ dialogData.confirmText }}
      </app-button>
    }

    @if (dialogData.secondaryActionText) {
      <app-button
        (click)="dialogRef.close('secondaryAction')"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
        class="confirmation-modal__actions-secondary-action"
      >
        {{ dialogData.secondaryActionText }}
      </app-button>
    }

    @if (dialogData.cancelText) {
      <app-button
        (click)="dialogRef.close('cancel')"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Secondary"
      >
        {{ dialogData.cancelText }}
      </app-button>
    }
  </div>
</div>
