<div
  #trigger
  (click)="toggle.emit()"
  (keydown.enter)="toggle.emit()"
  (keydown.space)="toggle.emit()"
  [class.cc-select-preview--size-default]="size === 'default'"
  [class.cc-select-preview--size-large]="size === 'large'"
  [class.cc-select-preview--state-active]="dropdownOpen"
  [class.cc-select-preview--state-disabled]="disabled"
  [class.cc-select-preview--state-error]="hasError"
  [class.cc-select-preview--appearance-default]="appearance === 'default'"
  [class.cc-select-preview--appearance-ghost]="appearance === 'ghost'"
  class="cc-select-preview"
  tabindex="0"
>
  @if (value) {
    <span class="cc-select-preview__value">
      {{ value }}
    </span>
  } @else {
    <span class="cc-select-preview__placeholder">
      {{ placeholder }}
    </span>
  }

  <i
    [class.icon-collapse]="dropdownOpen"
    [class.icon-expand]="!dropdownOpen"
    class="cc-select-preview__toggle"
  ></i>
</div>
